<template>
  <div>
    <b-modal
      id="modal-invitacion-nueva-sede"
      title="Exclusivo Usuarios Salud Total"
      ok-only
      ok-title="Aceptar"
      no-close-on-esc
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
    > 
      <p style="font-size: 1rem">
        Señor usuario <b>Salud Total</b>, lo invitamos a programar su toma de muestra de laboratorio en nuestra nueva sede: <br>
        <ul>
          <li><b>CISS - Centro Integral de Servicios de Salud Cra 29 47-56</b></li>
          <!-- <li><b>Cañaveral - Calle 33 No. 26 - 16 Cañaveral</b></li> -->
        </ul>
        
      </p>
    </b-modal>
    <b-modal
      id="modal-accept-politics"
      title="Información"
      ok-only
      ok-title="Aceptar"
      no-close-on-esc
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
    >
      <p style="font-size: 1rem">
        Señor usuario, para continuar debe indicar que acepta nuestra política
        de tratamiento de datos personales, consignada en la página web
        www.alianzadiagnostica.com
      </p>
    </b-modal>
    <b-modal
      id="modal-message-ciss"
      title="Información"
      ok-only
      ok-title="Aceptar"
      no-close-on-esc
      no-close-on-backdrop
      header-bg-variant="dark"
      header-text-variant="light"
    >
      <p style="font-size: 1rem">
        <b>Esta sede es exclusiva para la atención de Protegidos Salud Total</b>
      </p>
    </b-modal>
    <b-container>
      <b-row>
        <b-col>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <b-form-group id="input-group-1" class="text-left">
              <label
                for="documento"
                v-b-tooltip.hover.right
                :title="titleTooltip"
                ><b
                  >Documento Identificación
                  <span class="required-field">(*)</span></b
                ></label
              >
              <b-form-input
                id="input-documento"
                v-model="form.document"
                type="text"
                required
                placeholder="Documento Identificación"
                @keyup.enter="getUserInfo"
                @blur="getUserInfo"
                @keypress="resetForm"
                autocomplete="off"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" class="text-left">
              <label for="nombres" v-b-tooltip.hover.right :title="titleTooltip"
                ><b>Nombres <span class="required-field">(*)</span></b></label
              >
              <b-form-input
                id="input-nombres"
                v-model="form.name"
                type="text"
                required
                placeholder="Nombres"
                autocomplete="off"
                @keyup.enter="goApellidos"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group id="input-group-3" class="text-left">
              <label
                for="apellidos"
                v-b-tooltip.hover.right
                :title="titleTooltip"
                ><b>Apellidos <span class="required-field">(*)</span></b></label
              >
              <b-form-input
                id="input-apellidos"
                v-model="form.lastname"
                type="text"
                required
                placeholder="Apellidos"
                autocomplete="off"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group id="input-group-4" class="text-left">
              <label
                for="input-phone"
                v-b-tooltip.hover.right
                :title="titleTooltip"
                ><b>Telefono <span class="required-field">(*)</span></b></label
              >
              <b-form-input
                id="input-phone"
                v-model="form.phone"
                type="number"
                required
                placeholder="Teléfono o Celular"
                autocomplete="off"
              >
              </b-form-input>
            </b-form-group>
            <b-form-group id="input-group-5" class="text-left">
              <label
                for="input-email"
                v-b-tooltip.hover.right
                :title="titleTooltip"
                ><b>Email <span class="required-field">(*)</span></b></label
              >
              <b-form-input
                id="input-email"
                v-model="form.email"
                type="email"
                required
                placeholder="Email"
                autocomplete="off"
              >
              </b-form-input>
            </b-form-group>

            <b-form-group id="input-group-6" class="text-left">
              <label v-b-tooltip.hover.right :title="titleTooltip"
                ><b
                  >Eps
                  <span class="required-field">(*)</span></b
                ></label
              >
              <b-form-select
                v-model="form.eps"
                :options="epsOpt"
                placeholder="Seleccione la sede donde desea agendar"
              ></b-form-select>
            </b-form-group>

            <b-form-group id="input-group-6" class="text-left">
              <label v-b-tooltip.hover.right :title="titleTooltip"
                ><b
                  >Sede en la que desea agendar
                  <span class="required-field">(*)</span></b
                ></label
              >
              <b-form-select
                v-model="form.place"
                :options="placesOpt"
                placeholder="Seleccione la sede donde desea agendar"
                @change="getUserReservesOnChange($event)"
              ></b-form-select>
            </b-form-group>
            <b-form-group id="input-group-7" class="text-left">
              <b-form-checkbox v-model="form.acceptPolitics" name="checkbox-1">
                Acepta la política de tratamiento de datos personales consignada
                en la página web www.alianzadiagnostica.com
              </b-form-checkbox>
            </b-form-group>
            <!-- Check de si es un frotis vaginal o cultivo -->
            <b-form-group style="font-weight: bold; color: red; display: none">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                @change="changeCheck($event)"
                v-model="esFrotisCultivo"
              >
                Marque esta opción si es un exámen de frotis vaginal o un
                cultivo
              </b-form-checkbox>
            </b-form-group>
            <!-- fin del check -->
            <hr />
            <b-icon
              icon="arrow-clockwise"
              animation="spin"
              font-scale="4"
              v-if="isBussy"
            ></b-icon>
            <b-container v-if="userReserves.length > 0">
              <b-row>
                <b-col>
                  <p style="font-size: 1.1rem">
                    Usted tiene una reserva en la sede
                    <b>{{ form.place }}</b> para el día
                    <b>{{
                      this.$moment(userReserves[0].date)
                        .locale("es-mx")
                        .format("LL")
                    }}</b>
                    en el horario de
                    <b>{{ userReserves[0].schedule }}</b>
                  </p>
                </b-col>
              </b-row>
            </b-container>
            <b-container>
              <b-row>
                <!-- <b-col>
                  <b-button type="reset" variant="danger">
                    <div class="h3 mb-0">
                      <b-icon icon="clear"></b-icon>
                    </div>
                    Reset
                  </b-button>
                </b-col> -->
                <b-col class="text-center container-buttons">
                  <b-button
                    :pressed="true"
                    type="button"
                    variant="primary"
                    :disabled="!formValid"
                    @click="onSubmit"
                    style="font-size: 0.8rem"
                    class="mt-2"
                  >
                    <!-- <div class="h5 mb-2 mt-1"> -->
                    <b-icon
                      icon="calendar-date"
                      aria-hidden="true"
                      class="mr-2"
                    ></b-icon>
                    <!-- </div> -->
                    <span v-if="userReserves.length > 0"
                      >Modificar Reserva</span
                    >
                    <span v-else>Seleccionar Fecha</span>
                  </b-button>
                  <b-button
                    :pressed="true"
                    type="button"
                    variant="danger"
                    :disabled="!formValid"
                    @click="deleteUserReserve"
                    style="font-size: 0.8rem"
                    v-if="userReserves.length > 0"
                    class="ml-2 mt-2"
                  >
                    <!-- <div class="h5 mb-2 mt-1"> -->
                    <b-icon
                      icon="trash"
                      aria-hidden="true"
                      class="mr-2"
                    ></b-icon>
                    <!-- </div> -->
                    Cancelar Reserva
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions } from "vuex";
var moment_tz = require("moment-timezone");
import moment from "moment";
export default {
  name: "StepPersonalInfo",
  components: {},
  data() {
    return {
      form: {
        document: "",
        name: "",
        lastname: "",
        phone: "",
        email: "",
        id: "",
        place: "",
        eps: "",
        esFrotisCultivo: false,
        acceptPolitics: false,
      },
      show: true,
      fields: [
        { key: "date", label: "Fecha" },
        { key: "schedule", label: "Horario" },
      ],
      isBussy: false,
      placesOpt: [],
      epsOpt: [
        {value: 'Salud Total', text: 'Salud Total'},
        {value: 'Sanitas', text: 'Sanitas'},
        {value: 'Famisanar', text: 'Famisanar'},
        {value: 'Particular', text: 'Particular'},
        {value: 'Otra', text: 'Otra'},
      ],
      titleTooltip: "Campo requerido",
    };
  },
  computed: {
    ...mapState("users", ["userData"]),
    ...mapState("reserves", [
      "userReserves",
      "placeSelected",
      "esFrotisCultivo",
    ]),
    ...mapState("places", ["places"]),
    formValid() {
      return (
        this.form.name != "" &&
        this.form.lastname != "" &&
        this.form.phone != "" &&
        this.form.email != "" &&
        this.form.place != "" && 
        this.form.eps != "" &&
        this.form.acceptPolitics
      );
    },
    emailStateValidation() {
      if (this.userData.email) {
        return this.emailIsValid() ? true : false;
      }
      return null;
    },
  },
  mounted() {
    let lastCon = localStorage.getItem("last-connection");
    // console.log(moment_tz().tz("America/Bogota").diff(lastCon, "hours"));
    if (lastCon) {
      if (moment_tz().tz("America/Bogota").diff(lastCon, "hours") > 1) {
        localStorage.removeItem("schedules-local");
        localStorage.removeItem("places-local");
        localStorage.setItem(
          "last-connection",
          moment_tz().tz("America/Bogota").format()
        );
      }
    } else {
      localStorage.setItem(
        "last-connection",
        moment_tz().tz("America/Bogota").format()
      );
    }

    this.form.acceptPolitics = false;
    this.getPlacesAvailables();
    if (this.userData.document != "") {
      this.form.document = this.userData.document;
      this.form.name = this.userData.name;
      this.form.lastname = this.userData.lastname;
      this.form.phone = this.userData.phone;
      this.form.email = this.userData.email;
      this.form.id = this.userData.id;
      this.form.place = this.placeSelected;
      this.form.eps = this.userData.eps;
    }
    this.$bvModal.show("modal-invitacion-nueva-sede");
  },
  methods: {
    ...mapActions("users", [
      "setUserData",
      "resetUserData",
      "getUser",
      "deleteUser",
    ]),
    ...mapActions("reserves", [
      "getUserReserves",
      "setIdReserve",
      "setReserveSchedule",
      "setReserveDate",
      "setUserReserves",
      "deleteReserve",
      "setPlaceSelected",
      "setFrotisCultivo",
    ]),
    ...mapActions("places", ["getPlaces"]),
    emailIsValid() {
      const temp = this.form.email;
      let regEx = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
      return regEx.test(temp);
    },
    onSubmit(evt) {
      if (!this.emailIsValid()) {
        this.$toastr.e("Email no válido");
        return;
      }
      evt.preventDefault();

      if (!this.form.acceptPolitics) {
        this.$bvModal.show("modal-accept-politics");
        return;
      }

      if (
        this.form.document == "" ||
        this.form.name == "" ||
        this.form.lastname == "" ||
        this.form.phone == "" ||
        this.form.email == "" ||
        this.form.place == "" || 
        this.form.eps == "" 
      ) {
        return;
      }
      //Almacenamos en memoria los datos del usuario
      this.setUserData(this.form);

      //Si el usuario tiene reservas activas almacenamos en memoria los datos de la reserva
      if (this.userReserves.length > 0) {
        this.setIdReserve(this.userReserves[0].id);
        this.setReserveSchedule(this.userReserves[0].schedule);
        this.setReserveDate(this.userReserves[0].date);
      }

      this.setPlaceSelected({
        name: this.form.place,
        id: this.getIdPlace(this.form.place),
      });

      //Vamos al siguiente paso
      this.$router.push("stepDate");
    },
    onReset(evt) {
      evt.preventDefault();
      this.resetUserData();
      this.acceptPolitics = false;
    },
    async getUserInfo() {
      //Limpiamos datos de reserva alguna
      //if (this.userReserves.length > 0) {
      if (this.form.document == "") {
        return;
      }
      this.isBussy = true;
      this.setIdReserve("");
      this.setReserveSchedule("");
      this.setReserveDate("");
      this.setUserReserves([]);
      this.setPlaceSelected({
        name: "",
        id: "",
      });

      this.resetForm();

      this.setUserData({
        // document: "",
        name: "",
        lastname: "",
        phone: "",
        email: "",
        eps: ""
      });

      //Consultamos si el cliente existe
      let user = await this.getUser(this.form.document);
      if (Object.keys(user).length !== 0) {
        this.form.document = user.document;
        this.form.name = user.name;
        this.form.lastname = user.lastname;
        this.form.phone = user.phone;
        this.form.email = user.email;
        this.form.id = user.id;
        this.form.eps = user.eps;

        //Si existe el cliente consultamos si tiene reservas
        if (this.form.place != "") {
          this.getUserReserves(this.form.document)
            .then((reserves) => {
              if (reserves) {
                this.setUserReserves(reserves);
                //Si el usuario tiene reservas activas almacenamos en memoria los datos de la reserva
                if (this.userReserves.length > 0) {
                  this.setIdReserve(this.userReserves[0].id);
                  this.setReserveSchedule(this.userReserves[0].schedule);
                  this.setReserveDate(this.userReserves[0].date);
                  this.setPlaceSelected({
                    name: this.form.place,
                    id: this.getIdPlace(this.form.place),
                  });
                  this.setFrotisCultivo(this.userReserves[0].frotisCultivo);
                }
              }
              // this.isBussy = false;
            })
            .catch((err) => {
              console.log("Error obteniendo reservas del usuario", err);
              // this.isBussy = false;
            });
        }
      }
      this.goNombres();
      this.isBussy = false;
    },
    resetForm(e) {
      this.form.name = "";
      this.form.lastname = "";
      this.form.phone = "";
      this.form.email = "";
      this.form.place = "";
      this.form.eps = "";
      this.setUserReserves([]);
    },
    goNombres() {
      document.getElementById("input-nombres").focus();
    },
    goApellidos() {
      document.getElementById("input-apellidos").focus();
    },
    goPhone() {
      document.getElementById("input-phone").focus();
    },
    goEmail() {
      document.getElementById("input-email").focus();
    },
    deleteUserReserve() {
      this.deleteReserve().then((rta) => {
        this.deleteUser().then((rta) => {
          this.$toastr.s("Reserva eliminada con éxito.", "Cancelar reserva");
          this.form.document = "";
          this.resetForm();
        });
      });
    },
    async getPlacesAvailables() {
      await this.getPlaces();
      this.places.forEach((element) => {
        this.placesOpt.push({
          value: element.name,
          text: element.name + " => " + element.adress,
        });
      });
    },
    getUserReservesOnChange() {      
      this.setPlaceSelected({
        name: this.form.place,
        id: this.getIdPlace(this.form.place),
      });
      if (this.getIdPlace(this.form.place) == 'UD8w4Jge47pnNHAndPZB') {
        this.$bvModal.show("modal-message-ciss");
      }
      //Si existe el cliente consultamos si tiene reservas
      this.getUserReserves(this.form.document)
        .then((reserves) => {
          if (reserves) {
            this.setUserReserves(reserves);
            //Si el usuario tiene reservas activas almacenamos en memoria los datos de la reserva
            if (this.userReserves.length > 0) {
              this.setIdReserve(this.userReserves[0].id);
              this.setReserveSchedule(this.userReserves[0].schedule);
              this.setReserveDate(this.userReserves[0].date);
              this.setPlaceSelected({
                name: this.form.place,
                id: this.getIdPlace(this.form.place),
              });
              this.setFrotisCultivo(this.userReserves[0].frotisCultivo);
            }
          }
          // this.isBussy = false;
        })
        .catch((err) => {
          console.log("Error obteniendo reservas del usuario", err);
          // this.isBussy = false;
        });
    },
    getIdPlace(namePlace) {
      const place = this.places.filter((el) => {
        if (el.name == namePlace) {
          return el;
        }
      });
      return place[0].id;
    },
    changeCheck(value) {
      this.setFrotisCultivo(value);
    },
  },
};
</script>


<style scoped>
.required-field {
  font-size: 0.8rem;
  color: red;
}
</style>